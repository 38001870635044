//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.circleContainer
{
    $outerSize:      256px;

    display:         flex;
    justify-content: center;
    align-items:     center;
    position:        relative;
    width:           $outerSize;
    height:          $outerSize;

    .circle
    {
        $size:            138px;

        position:         absolute;
        border-radius:    50%;
        width:            $size;
        height:           $size;
        background-color: $colorWhite;
    }

    > :not(.circle)
    {
        z-index: $zIndexOne;
    }

    &.circleContainerGreen
    {
        @include triplePulseAnimations($colorMantis, 'green');

        .circle
        {
            &:nth-child(1)
            {
                animation: pulse-small-green 3s infinite;
            }

            &:nth-child(2)
            {
                animation: pulse-medium-green 3s infinite;
            }

            &:nth-child(3)
            {
                animation: pulse-big-green 3s infinite;
            }
        }
    }

    &.circleContainerRed
    {
        @include triplePulseAnimations($colorMandy, 'red');

        .circle
        {
            &:nth-child(1)
            {
                animation: pulse-small-red 3s infinite;
            }

            &:nth-child(2)
            {
                animation: pulse-medium-red 3s infinite;
            }

            &:nth-child(3)
            {
                animation: pulse-big-red 3s infinite;
            }
        }
    }
}
