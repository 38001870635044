//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../../styles/all';

.row
{
    display:         flex;
    flex-direction:  row;
    justify-content: space-between;

    &:not(:last-child)
    {
        margin-bottom: 35px;
    }

    .key
    {
        color: $colorGrey;
    }

    .value
    {
        font-weight: $fontWeightExtraBold;
        text-align:  right;
        color:       $colorBlueDark;
        white-space: pre-wrap;
    }
}
