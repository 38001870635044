//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.badgeLabel
{
    display:       inline-block;
    font-family:   $fontSourceOpenSans;
    font-weight:   $fontWeightBold;
    padding:       4px 7.5px 5px;
    border-radius: 13px;
    color:         $colorWhite;

    &.defaultMedium
    {
        background-color: $colorRegentGray;
    }

    &.defaultLarge
    {
        background-color: $colorTacao;
    }

    &.extraDeals
    {
        background-color: $colorMandy;
    }
}
