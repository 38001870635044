//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.userCheckInInfoOverlayContainer
{
    position:         fixed;
    z-index:          $zIndexUserCheckInInfoOverlay;
    top:              0;
    padding-top:      20px;
    padding-bottom:   20px;
    left:             0;
    width:            100vw;
    height:           100vh;
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      flex-start;
    justify-content:  center;
    background-color: $colorBlack40;
    overflow:         scroll;

    @media (min-height: $screen-height-md)
    {
        align-items:    center;
        padding-top:    0;
        padding-bottom: 0;
        overflow:       auto;
    }

    .userCheckInInfoOverlay
    {
        $padding:         28px;

        display:          flex;
        position:         relative;
        flex-flow:        column nowrap;
        width:            500px;
        padding:          68px $padding $padding;
        border-radius:    10px;
        background-color: $colorPlantation;

        .closeButtonIcon
        {
            position: absolute;
            top:      $padding;
            left:     $padding;
            width:    32px;
            cursor:   pointer;

            svg
            {
                @include svgColorFill($colorWhite !important);

                width:  24px !important;
                height: 24px !important;
            }
        }

        .titleRow
        {
            &.titleRowMargin
            {
                margin-bottom: 40px;
            }
        }

        .avatarRow
        {
            margin: 0 auto;

            &.avatarRowMargin
            {
                margin-bottom: 47px;
            }
        }

        .checkInStateRow
        {
            margin:     0 auto 25px;
            color:      $colorWhite;
            text-align: center;

            &.checkInStateSuccess,
            &.checkInStateFailed
            {
                font-weight: $fontWeightBold;

                &.checkInStateSuccess
                {
                    color: $colorMantis;
                }

                &.checkInStateFailed
                {
                    color: $colorMandy;
                }
            }

            .checkInStateExplanation
            {
                display:     block;
                color:       $colorWhite;
                font-weight: $fontWeightRegular;
            }
        }

        .checkInButtonRow
        {
            margin-top: 20px;
        }
    }
}
