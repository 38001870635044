//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$basePath: '../../assets/fonts/OpenSans/';

/* open-sans-regular - latin */
@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 400;
    src:         url('#{$basePath}open-sans-v34-latin-regular.eot');
    src:         local(''),
                 url('#{$basePath}open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'),
                 url('#{$basePath}open-sans-v34-latin-regular.woff2') format('woff2'),
                 url('#{$basePath}open-sans-v34-latin-regular.woff') format('woff'),
                 url('#{$basePath}open-sans-v34-latin-regular.ttf') format('truetype'),
                 url('#{$basePath}open-sans-v34-latin-regular.svg#OpenSans') format('svg');
}

/* open-sans-700 - latin */
@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 700;
    src:         url('#{$basePath}open-sans-v34-latin-700.eot');
    src:         local(''),
                 url('#{$basePath}open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'),
                 url('#{$basePath}open-sans-v34-latin-700.woff2') format('woff2'),
                 url('#{$basePath}open-sans-v34-latin-700.woff') format('woff'),
                 url('#{$basePath}open-sans-v34-latin-700.ttf') format('truetype'),
                 url('#{$basePath}open-sans-v34-latin-700.svg#OpenSans') format('svg');
}

/* open-sans-800 - latin */
@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 800;
    src:         url('#{$basePath}open-sans-v34-latin-800.eot');
    src:         local(''),
                 url('#{$basePath}open-sans-v34-latin-800.eot?#iefix') format('embedded-opentype'),
                 url('#{$basePath}open-sans-v34-latin-800.woff2') format('woff2'),
                 url('#{$basePath}open-sans-v34-latin-800.woff') format('woff'),
                 url('#{$basePath}open-sans-v34-latin-800.ttf') format('truetype'),
                 url('#{$basePath}open-sans-v34-latin-800.svg#OpenSans') format('svg');
}
