//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.loadingIndicator
{
    $size:      25px;
    $margin:    2px;
    $padding:   2px;
    $innerSize: calc(#{$size} - #{$padding} - #{$margin});
    $color:     $colorPlantation;

    margin:     auto 10px;
    display:    inline-block;
    position:   relative;
    width:      $size;
    height:     $size;

    div
    {
        box-sizing:    border-box;
        display:       block;
        position:      absolute;
        width:         $innerSize;
        height:        $innerSize;
        margin:        $margin;
        border:        $padding solid $color;
        border-radius: 50%;
        animation:     ring-rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color:  $color transparent transparent transparent;
    }

    div:nth-child(1)
    {
        animation-delay: -0.45s;
    }

    div:nth-child(2)
    {
        animation-delay: -0.3s;
    }

    div:nth-child(3)
    {
        animation-delay: -0.15s;
    }

    @keyframes ring-rotation
    {
        0%
        {
            transform: rotate(0deg);
        }

        100%
        {
            transform: rotate(360deg);
        }
    }
}
