//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$checkboxWidth: 20px;

.checkBoxWrapper
{
    display:        flex;
    flex-direction: column;
    margin:         0 0 10px 0;
}

.checkBoxWrapperNoMarginBottom
{
    margin-bottom: 0;
}

.checkBoxWrapperClickArea
{
    cursor:         pointer;
    display:        flex;
    flex-direction: row;
}

.checkBox
{
    width:            $checkboxWidth;
    height:           $checkboxWidth;
    border-radius:    5px;
    background-color: $colorGreyLight;
    margin-right:     16px;
}

.checkBoxChecked
{
    span
    {
        height: 100%;
    }

    svg
    {
        @include svgColorFill($colorGrey !important);

        height: 100%;
        width:  100%;
        margin: 0 0 0 0;
    }
}
