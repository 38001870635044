//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.dashboardContainer
{
    display:        flex;
    flex-direction: column;
    width:          100%;
    min-height:     100%;
    padding:        0 110px 50px;

    .titleContainer
    {
        width:  100%;
        margin: 30px 0 10px;
    }
}
