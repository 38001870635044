//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../../../styles/all';

.userCheckInDateAndName
{
    display:        flex;
    flex-direction: column;

    .userCheckInName
    {
        font-size:     16px;
        font-weight:   $fontWeightExtraBold;
        margin-bottom: 5px;
        color:         $colorBlueDark;
    }

    .checkInDateAndTime
    {
        :first-child
        {
            &::after
            {
                margin:  0 2px;
                content: '⋅';
            }
        }
    }
}
