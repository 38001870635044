//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@mixin svgColor($color)
{
    @include svgColorFill($color);
    @include svgColorStroke($color);
}

@mixin svgColorFill($color)
{
    *
    {
        fill: $color;
    }
}

@mixin svgColorStroke($color)
{
    *
    {
        stroke: $color;
    }
}

@mixin triplePulseAnimations($color, $suffix)
{
    /* stylelint-disable-line keyframes-name-pattern */
    @keyframes pulse-small-#{$suffix}
    {
        0%
        {
            box-shadow: 0 0 0 0 rgba($color, 0.9);
        }

        100%
        {
            box-shadow: 0 0 0 20px rgba($color, 0);
        }
    }

    /* stylelint-disable-line keyframes-name-pattern */
    @keyframes pulse-medium-#{$suffix}
    {
        0%
        {
            box-shadow: 0 0 0 0 rgba($color, 0.6);
        }

        100%
        {
            box-shadow: 0 0 0 40px rgba($color, 0);
        }
    }

    /* stylelint-disable-line keyframes-name-pattern */
    @keyframes pulse-big-#{$suffix}
    {
        0%
        {
            box-shadow: 0 0 0 0 rgba($color, 0.5);
        }

        100%
        {
            box-shadow: 0 0 0 60px rgba($color, 0);
        }
    }
}
