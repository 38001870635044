//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.iconButton
{
    width:           20px;
    height:          20px;
    cursor:          pointer;
    display:         flex;
    justify-content: center;
}

.iconButtonBigIcon
{

}

.iconButtonRedRounded
{
    $size:         20px;

    width:         $size;
    height:        $size;
    border-radius: $size * 0.5;
    background:    $colorMandy;
    transition:    transform 0.1s linear;

    &:hover
    {
        transform: scale(1.04);
    }

    svg
    {
        height: 10px;
        width:  10px;
    }
}
