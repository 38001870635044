//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/all';

*
{
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

body
{
    color:                   $colorGrey;
    font-family:             $fontSourceOpenSans;
    font-size:               14px;
    margin:                  0;
    padding:                 0;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
button
{
    text-align:   center;
    touch-action: manipulation;
}

.appContent
{
    display:        flex;
    flex-direction: column;
    min-height:     100vh;
    padding:        10px;
}
