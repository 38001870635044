//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$colorBlack40:         rgba(0 0 0 / 40%);
$colorBlueDark:        #1e1f20;
$colorBlueTransparent: rgba(36 35 112 / 8%);
$colorFlamingo:        #ec6737;
$colorGray:            #cccccc;
$colorGrey:            #9092a0;
$colorGreyLight:       #f6f7f8;
$colorManatee:         #9092ab;
$colorMandy:           #eb4b56;
$colorMantis:          #68ce67;
$colorPlantation:      #263e41;
$colorPlatinum:        #e6e6e8;
$colorRed:             #ff0000;
$colorRegentGray:      #8f9c9f;
$colorTacao:           #f3af90;
$colorWhite80:         rgba(255 255 255 / 80%);
$colorWhite:           #ffffff;

:export
{
    colorBlack40:         $colorBlack40;
    colorBlueDark:        $colorBlueDark;
    colorBlueTransparent: $colorBlueTransparent;
    colorFlamingo:        $colorFlamingo;
    colorGray:            $colorGray;
    colorGrey:            $colorGrey;
    colorGreyLight:       $colorGreyLight;
    colorManatee:         $colorManatee;
    colorMandy:           $colorMandy;
    colorMantis:          $colorMantis;
    colorPlantation:      $colorPlantation;
    colorPlatinum:        $colorPlatinum;
    colorRed:             $colorRed;
    colorRegentGray:      $colorRegentGray;
    colorTacao:           $colorTacao;
    colorWhite80:         $colorWhite80;
    colorWhite:           $colorWhite;
}
