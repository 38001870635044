//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headerContainer
{
    display:          flex;
    flex-direction:   row;
    justify-content:  space-between;
    background-color: $colorWhite;
    height:           96px;
    padding:          24px 0;

    .logoutButtonContainer
    {
        display:        flex;
        flex-direction: row;
        width:          100%;
        height:         100%;

        .logoutButton
        {
            opacity:    0;
            height:     48px;
            width:      48px;
            transition: opacity 0.2s ease-in-out,
                        transform 0.2s ease-in-out;
            transform:  translateX(-10px);

            @include svgColorFill($colorTacao !important);
        }

        &:hover
        {
            .logoutButton
            {
                opacity:   1;
                transform: translateX(0);
            }
        }
    }

    .searchContainer
    {
        display:        flex;
        flex-direction: row;

        .searchInput
        {
            width:        320px;
            margin-right: 20px;
        }

        .searchButton
        {
            width: 110px;
        }
    }
}
