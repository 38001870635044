//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.textInputWrapper
{
    $inputHeight:   48px;
    $iconPadding:   59px;
    $iconSize:      24px;

    display:        flex;
    flex-direction: row;
    width:          100%;
    position:       relative;

    .iconWrapper,
    .iconWrapperPre
    {
        pointer-events:  none;
        position:        absolute;
        top:             0;
        bottom:          0;
        right:           0;
        height:          $iconSize;
        width:           $iconSize;
        display:         flex;
        justify-content: center;
        align-items:     center;
        box-sizing:      border-box;
        margin:          auto 20px auto 0;

        &.iconWrapperPre
        {
            right:        unset;
            left:         0;
            margin-right: 0;
            margin-left:  20px;
        }
    }

    .textInput
    {
        width:         100%;
        background:    $colorGreyLight;
        border:        none;
        border-radius: 10px;
        height:        $inputHeight;
        min-height:    $inputHeight;
        outline:       none;
        font-size:     16px;
        color:         $fontSourceOpenSans;
        padding:       0 0 0 10px;
        box-sizing:    border-box;
        margin-bottom: 18px;

        &.textInputDisabled
        {
            color:  $colorGray;
            cursor: not-allowed;
        }

        &.textInputMultiLine
        {
            padding-top: 9px;
            min-width:   150px;
            max-height:  500px;
            max-width:   500px;
        }

        &.textInputSingleLine
        {

        }

        &.textInputWithPreIcon
        {
            padding-left: $iconPadding;
        }

        &.textInputWithPostIcon
        {
            padding-right: $iconPadding;
        }

        &::placeholder
        {
            color: $colorGrey;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
        {
            -webkit-appearance: none;
            margin:             0;
        }

        &[type=number]
        {
            -moz-appearance: textfield;
        }

        &:focus
        {
            border-color: $colorGray;
        }
    }

    &.textInputWrapperSmall
    {
        $inputHeightSmall: 21px;

        .textInput
        {
            padding:    0 4px;
            height:     $inputHeightSmall;
            min-height: $inputHeightSmall;
        }
    }

    &.textInputWrapperTextAlignmentLeft
    {
        .textInput
        {
            text-align: left;
        }
    }

    &.textInputWrapperTextAlignmentRight
    {
        .textInput
        {
            text-align: right;
        }
    }
}
