//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.userCheckInTable
{
    display:   flex;
    flex-flow: column wrap;
    width:     100%;

    .userCheckInRow
    {
        display:        flex;
        width:          100%;
        flex-direction: row;
        margin-bottom:  10px;
        padding:        20px 0;

        &:not(:first-child)
        {
            background-color: $colorWhite;
            border-top:       1.5px solid $colorPlatinum;
        }

        > *
        {
            display:       inline-block;
            padding-right: 10px;
            white-space:   pre-wrap;

            &:nth-child(1)
            {
                width: 9%;
            }

            &:nth-child(2)
            {
                width: 25%;
            }

            &:nth-child(3)
            {
                width: 25%;
            }

            &:nth-child(4)
            {
                width: 25%;
            }

            &:nth-child(5)
            {
                width: 13%;
            }

            &:nth-child(6)
            {
                width:         3%;
                align-content: flex-end;
            }
        }

        .infoButton
        {
            padding: 0;
            border:  none;

            .infoIcon
            {
                svg
                {
                    @include svgColor($colorWhite);
                }
            }
        }
    }
}
