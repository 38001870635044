//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$mainColorError:   $colorMandy;
$mainColorSuccess: $colorMantis;
$backgroundColor:  $colorWhite;

.alertBox
{
    padding:       12px 32px 12px 53px;
    border-radius: 4px;
    font-size:     15px;
    border-width:  1px;
    border-style:  solid;
    margin:        0 0 20px 0;
    position:      relative;
}

.alertBoxCloseButtonWrapper
{
    position: absolute;
    top:      10px;
    right:    10px;
}

.alertBoxError
{
    border-color:     $mainColorError;
    background-color: $backgroundColor;
    color:            $mainColorError;

    svg
    {
        @include svgColor($mainColorError);
    }

    .alertBoxIcon
    {
        background: $mainColorError;

        svg
        {
            @include svgColor($colorWhite);

            margin: 1px 0 0 1px;
        }
    }
}

.alertBoxIcon
{
    $size:           20px;

    width:           $size;
    height:          $size;
    border-radius:   $size * 0.5;
    position:        absolute;
    top:             50%;
    left:            18px;
    margin:          -($size * 0.5) 0 0 0;
    display:         flex;
    justify-content: center;
    align-items:     center;
}

.alertBoxSuccess
{
    border-color:     $mainColorSuccess;
    background-color: $backgroundColor;
    color:            $mainColorSuccess;

    svg
    {
        @include svgColor($mainColorSuccess);
    }

    .alertBoxIcon
    {
        background: $mainColorSuccess;

        svg
        {
            @include svgColor($colorWhite);
        }
    }
}
