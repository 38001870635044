//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.button
{
    font-family:      $fontSourceOpenSans;
    font-weight:      $fontWeightExtraBold;
    font-size:        16px;
    text-align:       center;
    padding:          10px;
    border:           1.5px solid $colorRegentGray;
    border-radius:    10px;
    color:            $colorWhite;
    background-color: $colorPlantation;
    cursor:           pointer;

    &.fullWidth
    {
        width:   100%;
        display: block;
    }

    &.transparentThinBorder
    {
        background-color: transparent;
    }
}
