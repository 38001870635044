//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.inputContainer
{
    margin-bottom: 30px;
}

.forgotPasswordContainer
{
    margin-top: 20px;
    text-align: center;

    .forgotPasswordLink
    {
        margin-left: 5px;
    }
}
